// ProductCard.js
import { useEffect, useState, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { generateLinkWithLang } from '../../../../../utils/linkGenerator';
import CustomButton from '../../../../general/button';
import { useDispatch } from 'react-redux';
import useCart from '../../../../../hooks/cart/useCart';
import { openSheet } from '../../../../../store/sheetToggler';

const ProductCard = ({
  product,
  alt,
  buttonLabel,
  _lang,
  removeDataLayer,
  isVisibleDescription,
  selectedCurrencyStore,
  handleViewItemDataLayer,
  handleIsAnyAmount,
  ShowCurrencies,
}) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const ref = useRef(null);

  const dispatch = useDispatch();
  const { handleAddToGiving: handleAddToGivingCart } = useCart();

  const handleHeight = () => {
    setShowMore(!showMore);
  };

  const handleAddProduct = product => {
    handleAddToGivingCart(product);
    dispatch(openSheet());
  };

  useEffect(() => {
    if (product) {
      handleIsAnyAmount(product);
      if (ref.current && ref.current.clientHeight > 60) {
        setIsOverflow(true);
      }
    }
    // Include dependencies that affect the rendering of the ref'd component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, ref.current]);

  console.log(product, 'checking');

  return (
    <div className="w-full shadow-lg bg-white pb-3 md:pb-5 h-full">
      {/* Image Section */}
      <div
        className="overflow-hidden img-hover-zoom w-full sm:min-h-[169px] md:min-h-[210px] lg:min-h-[301px] xl:min-h-[280px] 2xl:min-h-[344px] tooltip [&>span]:!block cursor-pointer"
        onClick={() => {
            product.product_type ? navigate(generateLinkWithLang(product.short_description, lang)) :
                navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));
        }}
      >
        {product.image_link ? (
          <LazyLoadImage
            alt={alt || product.alt || product.name}
            effect="blur"
            width="100%"
            delayTime={500}
            afterLoad={() => {
              !removeDataLayer && handleViewItemDataLayer(product, selectedCurrencyStore);
            }}
            className="cursor-pointer block"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/general/logo-replace.png';
            }}
            src={product.image_link}
          />
        ) : (
          <img className="w-full" src="/images/general/logo-replace.png" alt="Placeholder" />
        )}
      </div>

      {/* Product Name */}
      <p
        className={`text-[#00a3da] cursor-pointer flex justify-center items-center text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center px-1 mt-3 min-h-[56px] ${
          isVisibleDescription ? 'md:mb-2 xs:mb-2' : 'xs:mb-2 md:mb-0'
        } mt-2 leading-4 md:leading-none uppercase ${_lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-medium'}`}
        onClick={() => {
          if (product.product_type) {
            navigate(generateLinkWithLang(product.short_description, lang));
          } else {
            navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));
          }
        }}
      >
        {product.alternativeName || product.name || <Skeleton height={56} />}
      </p>

      {/* Description Section */}
      <div className="relative text-center">
        {(isVisibleDescription || showMore) && (
          <div
            className={`transition-all duration-300 text-center my-2 relative overflow-hidden ${
              showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[78px] mb-0'
            }`}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: product.product_type ? product.description : product.short_description || product.description,
              }}
              ref={ref}
              className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base overflow-hidden transition-all duration-200 min-h-[65px] md:min-h-[79px] px-2 md:px-3 ${
                _lang === 'ar' ? 'font-[AlmariMedium]' : ''
              }`}
            ></p>
          </div>
        )}
        {(isOverflow || !isVisibleDescription) && (
          <ChevronDownIcon
            onClick={handleHeight}
            className={`md:block w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${showMore ? 'transform rotate-180' : ''}`}
          />
        )}
      </div>

      {/* Pricing and Button */}
      <div className="grid grid-cols-6 gap-1 2xl:gap-2">
        <div  className={`text-[#f60362] ${product.product_type ? "invisible" : ''} col-span-6 md:text-xl text-center font-bold ${_lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
        { handleIsAnyAmount(product) ? (
          _lang === 'ar'
            ? 'أي مبلغ'
            : _lang === 'fr'
            ? "N'importe quel montant"
            : _lang === 'es'
            ? 'Cualquier cantidad'
            : 'Any Amount'
        ) : (
          <div className="flex items-center justify-center">
            <div>
              {ShowCurrencies(
                selectedCurrencyStore,
                product[selectedCurrencyStore.toLowerCase()] * (product.quantity || 1),
                true
              )}
            </div>
            <span className="text-xs ml-0.5">{selectedCurrencyStore}</span>
          </div>
        )}
        </div>
        <div className="flex justify-center col-span-6">
          <CustomButton
            
            onClick={() => {
              if (product.product_type) {
                navigate(generateLinkWithLang(product.short_description, lang));
              } else {
                handleAddProduct(product);
              }
            }}
            title={
              buttonLabel ||
              (product.product_type
                ? product.product_type
                : _lang === 'ar'
                ? 'تبرع الان'
                : _lang === 'fr'
                ? 'Faites un don maintenant'
                : _lang === 'es'
                ? 'Dona ahora'
                : 'Donate Now')
            }
            className={`mx-auto ${_lang === 'ar' ? 'font-[AlmariMedium]' : ''} !rounded-none`}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
