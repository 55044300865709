// handleDaysLeft.js

import dayjs from 'dayjs';
import { getCampaignDates, updateCampaignEndDate } from './dates.js';

// Configuration Constants
const EXTENSION_DAYS = 30;
const MIN_DIFFERENCE_DAYS = 10;
const MAX_DAYS_LEFT = 30;

/**
 * Handles the days left for a specific campaign.
 *
 * @param {string} campaignName - The name of the campaign to handle.
 * @returns {number|null} - The number of days left for the campaign after processing, or null if campaign not found.
 */
const handleDaysLeft = (campaignName) => {
  try {
    const now = dayjs();

    // Use 'let' since 'campaigns' will be modified if sorted
    let campaigns = getCampaignDates();

    // Find the target campaign by name
    const targetCampaign = campaigns.find(
      (campaign) => campaign.name === campaignName
    );

    if (!targetCampaign) {
      console.error(`Campaign "${campaignName}" not found.`);
      return null;
    }

    // Use 'let' since 'endDate' and 'daysLeft' will be modified
    let endDate = dayjs(targetCampaign.endDate, 'YYYY-MM-DD');
    let daysLeft = endDate.diff(now, 'day');

    // **1. Handle Past End Dates**
    if (endDate.isBefore(now, 'day')) {
      endDate = now.add(EXTENSION_DAYS, 'day');
      daysLeft = endDate.diff(now, 'day');
      updateCampaignEndDate(campaignName, endDate.format('YYYY-MM-DD'));
      // console.log(
      //   `End date for "${campaignName}" was in the past. Reset to ${endDate.format(
      //     'YYYY-MM-DD'
      //   )}. Days left set to: ${daysLeft}`
      // );
    }

    // **2. Automatically Extend Campaign if Days Left < 10**
    const lastExtended = targetCampaign.lastExtended
      ? dayjs(targetCampaign.lastExtended, 'YYYY-MM-DD')
      : null;
    const canExtend =
      !lastExtended || now.diff(lastExtended, 'day') >= EXTENSION_DAYS;

    if (daysLeft < 10 && canExtend) {
      // Extend by 30 days
      endDate = endDate.add(EXTENSION_DAYS, 'day');
      daysLeft = endDate.diff(now, 'day');

      // Ensure 10-day difference with other campaigns
      const otherCampaigns = campaigns.filter(
        (campaign) => campaign.name !== campaignName
      );

      // Check for conflicts
      let conflict = otherCampaigns.some((campaign) => {
        const otherEndDate = dayjs(campaign.endDate, 'YYYY-MM-DD');
        const difference = endDate.diff(otherEndDate, 'day');
        return Math.abs(difference) < MIN_DIFFERENCE_DAYS;
      });

      if (conflict) {
        // Find the nearest available date that satisfies the 10-day difference
        let adjustedEndDate = endDate;
        let adjustment = 1; // Start checking from +1 day
        let conflictExists = true;

        while (conflictExists) {
          adjustedEndDate = adjustedEndDate.add(1, 'day');
          const newConflict = otherCampaigns.some((campaign) => {
            const otherEndDate = dayjs(campaign.endDate, 'YYYY-MM-DD');
            const difference = adjustedEndDate.diff(otherEndDate, 'day');
            return Math.abs(difference) < MIN_DIFFERENCE_DAYS;
          });

          if (!newConflict) {
            conflictExists = false;
            endDate = adjustedEndDate;
            daysLeft = endDate.diff(now, 'day');
          }

          adjustment++;
          // Prevent infinite loop
          if (adjustment > 365) {
            console.error(
              `Unable to find a suitable end date for "${campaignName}" within a year.`
            );
            break;
          }
        }
      }

      // Cap days left to MAX_DAYS_LEFT
      if (daysLeft > MAX_DAYS_LEFT) {
        endDate = now.add(MAX_DAYS_LEFT, 'day');
        daysLeft = endDate.diff(now, 'day');
      }

      // Update the campaign end date
      updateCampaignEndDate(campaignName, endDate.format('YYYY-MM-DD'));
      console.log(
        `End date for "${campaignName}" extended to ${endDate.format(
          'YYYY-MM-DD'
        )}. New days left: ${daysLeft}`
      );
    }

    // **3. Cap Days Left to Maximum of 30 Days**
    if (daysLeft > MAX_DAYS_LEFT) {
      endDate = now.add(MAX_DAYS_LEFT, 'day');
      daysLeft = endDate.diff(now, 'day');
      updateCampaignEndDate(campaignName, endDate.format('YYYY-MM-DD'));
      console.log(
        `End date for "${campaignName}" capped to ${endDate.format(
          'YYYY-MM-DD'
        )}. Days left set to: ${daysLeft}`
      );
    }

    // **4. Final Log for the Campaign**
    console.log(`"${campaignName}" has ${daysLeft} days left.`);

    return daysLeft;
  } catch (error) {
    console.error(`Error handling campaign "${campaignName}":`, error);
    return null;
  }
};

export default handleDaysLeft;
