import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../store/user";
import ReactSelect from 'react-select';
import creditImg from "../../../../../images/payment/credit-cards.png";
import SelectCurrency from "../../../../general/selectCurrency";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectProducts } from "../../../../../store/products";
import CustomButton from "../../../../general/button";
import ShowCurrencies from "../../../../../utils/showCurrencies";
import useCart from '../../../../../hooks/cart/useCart';
import { openSheet } from '../../../../../store/sheetToggler';

const styles = {
    menuList: (base) => ({
        ...base,
        '::-webkit-scrollbar': {
            width: '6px',
            height: '0px',
        },
        '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
            background: '#888',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    }),
};

const WidgetHome = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const {
        defaultPrice,
        defaultName = 'Lebanon Emergency Appeal',
        defaultCreator = 'EMR-LEB-24-000',
        addToCart = true,
        productCreators, // NEW: Comma-separated creators passed in from Builder
    } = props;

    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector(selectProducts);
    const dispatch = useDispatch();
    const [addProduct] = useHandleAddProduct();
    const { handleAddToGiving: handleAddToGivingCart } = useCart();

    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedProduct, setSelectedProduct] = useState({
        creator: defaultCreator,
        label: defaultName,
        value: 674,
    });
    const [selectedPrice, setSelectedPrice] = useState(defaultPrice || 200);
    const [productAmount, setProductAmount] = useState(defaultPrice || 200);
    const [currentProduct, setCurrentProduct] = useState({
        creator: defaultCreator,
        label: defaultName,
        value: '',
    });

    // You can tweak these suggested prices or make them configurable too if needed
    const suggestedPrices = [100, 150, 200, 500];

    /* ------------------------------ Start methods ----------------------------- */
    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value);
    };

    const handleProduct = (value) => {
        // Match the selected item from our allProducts array
        allProducts.forEach((item) => {
            if (item.id === value.value) {
                setSelectedProduct(item);
            }
        });
        setCurrentProduct(value);
    };

    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        const customAmount = Number(productAmount)
            ? Number(productAmount)
            : Number(selectedPrice)
                ? Number(selectedPrice)
                : 100;

        tempProduct = {
            ...tempProduct,
            quantity: customAmount,
        };

        if (addToCart) {
            handleAddToGivingCart(tempProduct, 'custom');
            dispatch(openSheet());
        } else {
            addProduct({ product: tempProduct, currency: currentCurrency });
        }
    };

    /* ------------------------------- Start hooks ------------------------------ */
    const getAllProducts = (productsData) => {
        setIsLoaded(true);

        // Parse comma-separated creators from props
        const creatorsArray = productCreators
            ? productCreators.split(',').map((c) => c.trim())
            : [];

        let tempMakeDonationProducts = [];
        let tempProductsList = [];

        // Loop over all products + subcategories
        productsData.forEach((item) => {
            item.sub_categories?.forEach((subCat) => {
                subCat.products?.forEach((p) => {
                    // Check if product is active and creator is in our list
                    if (Number(p.status) === 1 && creatorsArray.includes(p.creator)) {
                        tempMakeDonationProducts.push(p);
                        tempProductsList.push({
                            value: p.id,
                            label: p.name,
                            creator: p.creator,
                        });

                        // If this is our default product
                        if (p.creator === defaultCreator) {
                            setSelectedProduct(p);
                            setCurrentProduct({
                                value: p.id,
                                label: p.name,
                                creator: p.creator,
                            });
                        }
                    }
                });
            });
        });

        setProductsList(tempProductsList);
        setAllProducts(tempMakeDonationProducts);
    };

    useEffect(() => {
        if (products && Object.keys(products).length) {
            getAllProducts(products);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
        } else {
            setCurrentCurrency('AUD');
        }
    }, [selectedCurrencyStore]);

    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center md:top-[64px] lg:top-[96px] z-30">
            <div className="w-full md:container md:mx-auto">
                <div className="grid grid-cols-12 lg:grid-cols-[1.5fr_1fr_1.5fr_1fr_1fr] gap-2 lg:gap-2 mx-auto lg:px-2">
                    {/* Currency + Amount */}
                    <div className="col-span-12 md:col-span-5 lg:col-span-1 order-3 md:order-1 flex items-center bg-white rounded-md h-[38px]">
                        <div className="min-w-max">
                            <SelectCurrency
                                onChangeCurrency={(e) => handleCurrency(e)}
                                classNamePrefix="home_select"
                            />
                        </div>
                        <input
                            value={productAmount}
                            name="amount"
                            className="h-[38px] text-[#777] w-full p-2 rounded-lg text-sm pl-1 md:text-base focus:outline-none"
                            type="number"
                            min="0"
                            onChange={(e) => {
                                const regex = /^(?:[1-9]\d*)?$/;
                                if (regex.test(e.target.value)) {
                                    setProductAmount(e.target.value);
                                    setSelectedPrice(null);
                                }
                            }}
                            placeholder="Amount"
                        />
                    </div>

                    {/* Suggested Prices */}
                    <div className="col-span-12 lg:col-span-1 order-2 md:order-3 lg:order-2 grid grid-cols-12 lg:flex gap-2">
                        {suggestedPrices.map((price, index) => (
                            <div key={index} className="col-span-3">
                                <div
                                    className={`h-[38px] text-sm transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[60px] rounded-md ${
                                        selectedPrice === price
                                            ? 'bg-[#F60362] text-white'
                                            : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                                    }`}
                                    onClick={() => {
                                        setSelectedPrice(price);
                                        setProductAmount('');
                                    }}
                                >
                                    {ShowCurrencies(currentCurrency, price, false)}
                                    <span className="uppercase ml-1 text-xs">
                    {currentCurrency}
                  </span>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Products Select */}
                    <div className="col-span-12 md:col-span-7 lg:!col-span-1 order-1 md:order-2 lg:order-3">
                        <ReactSelect
                            options={productsList}
                            className="max-h-[48px] [&>div]:!rounded-md lg:w-[280px]"
                            classNamePrefix="home-select"
                            value={currentProduct}
                            isSearchable={false}
                            styles={styles}
                            onChange={(val) => handleProduct(val)}
                        />
                    </div>

                    {/* Credit Card Image */}
                    <div className="col-span-6 lg:col-span-1 order-4 rounded-md px-3 h-[38px] bg-[#80ceff] flex items-center justify-center">
                        <img
                            src={creditImg}
                            alt="MATW"
                            className="w-[125px] md:w-auto h-auto"
                        />
                    </div>

                    {/* Quick Donate Button */}
                    <div className="order-5 col-span-6 lg:col-span-1">
                        <CustomButton
                            onClick={handleAddNewProduct}
                            title="Quick Donate"
                            disabled={!isLoaded}
                            className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 ${
                                isLoaded ? 'blob red' : ''
                            }`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * Register with Builder and add a new 'productCreators' input
 * to allow specifying a comma-separated list of product creators.
 */
Builder.registerComponent(WidgetHome, {
    name: 'WidgetHomeV2',
    inputs: [
        { name: 'defaultPrice', type: 'string' },
        { name: 'defaultName', type: 'string' },
        { name: 'defaultCreator', type: 'string' },
        {
            name: 'productCreators',
            type: 'string',
            helperText: 'Comma-separated list of product creators to show',
        },
    ],
});

export default WidgetHome;
