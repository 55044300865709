import { useCallback, useEffect, useState } from 'react';
import { Base64 } from 'js-base64';

const InAppBrowserRedirect = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [browserName, setBrowserName] = useState('Chrome');
  const [redirecting, setRedirecting] = useState(false);
  const [basketStates, setBasketStates] = useState([]);
  const [campaignData, setCampaignData] = useState(null);

  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  // Detect in-app browser and set popup visibility
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isInAppBrowser =
      userAgent.includes('fbav') ||
      userAgent.includes('fb_iab') ||
      userAgent.includes('fban') ||
      userAgent.includes('instagram') ||
      userAgent.includes('tiktok');

    if (isInAppBrowser) {
      setShowPopup(true);
      detectBrowser(userAgent);
    }
  }, []);

  // Detect browser type
  const detectBrowser = userAgent => {
    if (userAgent.includes('chrome')) setBrowserName('Chrome');
    else if (userAgent.includes('safari')) setBrowserName('Safari');
    else if (userAgent.includes('firefox')) setBrowserName('Firefox');
    else if (userAgent.includes('edge')) setBrowserName('Edge');
  };

  // Handle sharing the link
  const handleShareLink = useCallback(() => {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.search;

    if (!basketStates?.products) return;

    const formattedProducts = basketStates.products.map(product => ({
      product: product.product.creator,
      currency: product.currency.toUpperCase(),
      quantity: product.quantity,
      recurring: product.recurring,
    }));


    const utm_source = campaignData?.campaign_source;
    const utm_medium = campaignData?.campaign_medium;
    const utm_campaign = campaignData?.campaign_name;
    const fbclid = campaignData?.fbclid;

    let obj = Base64.encode(JSON.stringify(formattedProducts));
    let link = `${window.location.origin}/checkout?` +
        `${(utm_source && utm_source !== 'undefined') ? `utm_source=${utm_source}&` : ''}` +
        `${(utm_medium && utm_medium !== 'undefined') ? `utm_medium=${utm_medium}&` : ''}` +
        `${(utm_campaign && utm_campaign !== 'undefined') ? `utm_campaign=${utm_campaign}&` : ''}` +
        `${(fbclid && fbclid !== 'undefined') ? `fbclid=${fbclid}&` : ''}` +
        `id=${obj}${params}`;


    copyToClipboard(link);
    openInSystemBrowser(link);
  }, [basketStates]);

  // Copy text to clipboard
  const copyToClipboard = text => {
    const input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    try {
      input?.setSelectionRange(0, 99999);
    } catch {}
    document.execCommand('copy');
    input.remove();
  };

  // Open URL in system browser
  const openInSystemBrowser = url => {
    setRedirecting(true);

    const isAndroid = navigator.userAgent.toLowerCase().includes('android');
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isAndroid) {
      const fullUrl = url.startsWith('http') ? url : `https://${url}`;
      const intentURL = `intent://${fullUrl.replace(
        /^https?:\/\//,
        '',
      )}#Intent;action=android.intent.action.VIEW;scheme=https;package=com.android.chrome;end`;

      console.log('Opening Intent URL:', intentURL);
      window.location.href = intentURL;
    } else if (isIOS) {
      const chromeScheme = url.startsWith('https://') ? 'googlechromes://' : 'googlechrome://';
      window.location.href = chromeScheme + url.replace(/^https?:\/\//, '');
    } else {
      window.open(url, '_blank');
    }
  };

  // Load basket data from localStorage
  useEffect(() => {
    const productData = localStorage.getItem('BASKET_CACHE_KEY');
    if (productData) {
      try {
        const parsedData = JSON.parse(productData);
        setBasketStates(parsedData);
      } catch (error) {
        console.error('Failed to parse basket data:', error);
      }
    }
  }, []);

  useEffect(() => {
    const campaignCookie = getCookie('campaign_data');
    if (campaignCookie) {
      try {
        const parsedCampaignData = JSON.parse(decodeURIComponent(campaignCookie));
        setCampaignData(parsedCampaignData);
      } catch (error) {
        console.error('Failed to parse campaign data:', error);
      }
    }
  }, []);

  return (
    showPopup && (
      <div className="top-0 left-0 right-0 w-full bg-[#f60362] p-4 z-[210] flex justify-between items-center shadow-lg">
        {/* Left Side: Text */}

        {/* Right Side: Button */}
        <button onClick={handleShareLink} className="bg-primary hover:bg-blue-600 w-full text-white font-bold py-2 px-4 rounded-md transition-all">
          {redirecting ? 'Redirecting...' : 'No saved details? Click here to continue in your usual browser'}
        </button>
      </div>
    )
  );
};

export default InAppBrowserRedirect;
