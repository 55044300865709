import React, { useEffect, useRef, useState } from 'react';
import { generateLinkWithLang } from '../../../utils/linkGenerator';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import getSymbolFromCurrency from 'currency-symbol-map';
import { HiShoppingCart } from 'react-icons/hi';
import { openSheet } from '../../../store/sheetToggler';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { selectWidgetProducts } from '../../../store/widgetCart';
import CustomButton from '../../../components/general/button';
import useCart from '../../../hooks/cart/useCart';

export const ProductBoxCart = ({
  product,
  onAdd,
  onCheck,
  isPalestineRefugee = false,
  _lang = 'en',
  isStaticProduct,
  staticLink,
  alt,
  arabic = false,
  currency,
  buttonLable,
  scrollTop,
  staticLinkText,
  linkBtnClasses,
  stateVariableObj,
}) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [isAnyAmount, setIsAnyAmount] = useState(false);
  const [_quantity, setQuantity] = useState(200);
  const dispatch = useDispatch();
  const widgetProducts = useSelector(selectWidgetProducts);

  const [showMore, setShowMore] = useState(false);

  const handleCustomProductAmount = product => {
    let hasQuantity = product.quantity ? (product.quantity > 1 ? true : false) : false;
    if (Number(product.aud) <= 2 && !hasQuantity) {
      setIsAnyAmount(true);
    } else {
      setIsAnyAmount(false);
    }
  };

  const ShowCurrencies2 = (currency, _price) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    } 
    return _final;
  };

  const HandleIsPalestineRefugee = () =>
    isPalestineRefugee
      ? navigate(generateLinkWithLang(`/appeals/palestine/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))
      : isStaticProduct
      ? navigate(generateLinkWithLang(`${staticLink ? staticLink : '/zakat-calculator'}`, lang))
      : navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang));

      const handleAddToCart = (product,quantity) => {
        onAdd(product,quantity)
        dispatch(openSheet())
      };

      const handleProductCounter = (creator) => { 
        const product = widgetProducts?.cartCounter?.find((item) => item.creator === creator);
      
        if (product) {
          if(product.counter >= 99) {
            return 99;
          } else {
            return product.counter || 0;
          }
        } else {
          return 0; 
        }
      };

      const handleHeight = () => {
        setShowMore(!showMore);
      };

      const toProperCase = (text) => {
        return text
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }

      const handleInputChange = (e) => {
        const value = e.target.value.trim();
        const quantity = isNaN(value) || value === '' || Number(value) <= 0 ? 1 : Math.floor(Number(value));
        setInputValue(quantity)
        
      };

    const [inputValue,setInputValue] = useState(1);

    useEffect(() => {
      if (product) {
        handleCustomProductAmount(product);
        if (product.aud > 1) {
          setQuantity(1);
        } else {
          setQuantity(200);
        }
      }
       // Include dependencies that affect the rendering of the ref'd component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  
  // Add a separate useEffect for inputValue
  useEffect(() => {
      isAnyAmount ? setInputValue(200) : setInputValue(1);
  }, [isAnyAmount]);

  return (
    <>
      <div className={`w-full gap-2 md:gap-4 grid grid-cols-[auto,1fr] shadow-[0_7px_29px_0px_rgba(100,100,111,0.2)] bg-white`}>
        {/* Image Container */}
        <div className={`overflow-hidden tooltip img-hover-zoom w-[72px] md:w-[108px] aspect-square`} onClick={HandleIsPalestineRefugee}>
          {product ? (
            product.image_link ? (
              <LazyLoadImage
                alt={alt || product.alt || product.name}
                effect="blur"
                className={`!rounded-none cursor-pointer w-full h-full`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/images/general/logo-replace.png';
                }}
                src={product.image_link}
              />
            ) : (
              <img className="w-full h-full object-cover" src="/images/general/logo-replace.png" alt="" />
            )
          ) : (
            <Skeleton height={100} width={100} />
          )}
        </div>
        {/* Right Container */}
        <div className={`flex flex-col justify-between md:items-baseline gap-1 sm:gap-3 p-2 md:p-3 ${arabic ? 'pl-2' : 'pl-0'}`}>
          <div className={`flex gap-1 flex-col items-center overflow-hidden transition-all duration-300 ${showMore ? 'h-auto' : 'max-h-[24px]'}`}>
            <div className="w-full flex gap-2 items-center">
              <p onClick={HandleIsPalestineRefugee} className={`text-[#00a3da] cursor-pointer md:text-lg font-semibold md:font-bold ${!showMore ? 'line-clamp-1' : ''}`}>
                <span className="sm:hidden">{product ? toProperCase(product.alternativeName || product.name) : <Skeleton width={70} height={12} count={1} />}</span>
                <span className="hidden sm:block">
                  {product ? product.alternativeName || product.name : <Skeleton width={200} height={12} count={1} />}
                </span>
              </p>
              <span className="block basis-[18px]">
                <ChevronDownIcon
                  onClick={handleHeight}
                  className={`md:block w-[18px] h-[18px] text-[#00a3da] transition-all duration-300 cursor-pointer ${
                    showMore ? 'transform rotate-180' : ''
                  }`}
                />
              </span>
            </div>
            <p className={`text-stone-500 text-sm overflow-hidden transition-all duration-200 ${showMore ? 'h-auto mb-2' : 'max-h-0 mb-0'}`}>
              {product.description}
            </p>
          </div>
          {/* Button Container */}
          <div className="flex gap-3 items-center w-full justify-between">
            {product ? (
              <div
                className={`text-[#f60362] text-sm md:text-lg font-semibold truncate min-w-max ${
                  arabic || _lang === 'ar' ? 'font-[AlmariMedium]' : ''
                }`}
              >
                {isStaticProduct ? (
                  ' '
                ) : isAnyAmount ? (
                  arabic || _lang === 'ar' ? (
                    'أي مبلغ'
                  ) : _lang === 'fr' ? (
                    `N'importe quel montant`
                  ) : (
                    'Any Amount'
                  )
                ) : (
                  <div className="flex items-center">
                    <div>{ShowCurrencies2(currency, product[currency.toLowerCase()] * (product.quantity || 1), true)}</div>
                    <span className="text-xs ml-0.5">{currency}</span>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Skeleton width={90} height={20} />
              </div>
            )}
            {
              product ? (
                isStaticProduct ? (
                  scrollTop && scrollTop >= 0 ? (
                    <CustomButton
                      onClick={() => {
                        window.scroll({ top: scrollTop, left: 0, behavior: "smooth" });
                      }}
                      title={staticLinkText ? staticLinkText : "Zakat Calculator"}
                      className={`${linkBtnClasses ? linkBtnClasses : "!rounded-none !text-xs !h-[32px] !px-3 mt-2"}`}
                    />
                  ) : (
                    <CustomButton
                      state={stateVariableObj}
                      link={`${staticLink ? staticLink : "/zakat-calculator"}`}
                      title={staticLinkText ? staticLinkText : "Zakat Calculator"}
                      className={`${linkBtnClasses ? linkBtnClasses : "!rounded-none !text-xs !h-[32px] !px-3 mt-2"}`}
                    />
                  )
                ) : (
                  <div className="flex items-center gap-2">
                    <div
                        className={`flex border-[.1rem] border-[#d2d5d999] hover:border-[#25b9db] focus:border-[#25b9db] w-[76px] text-[#777] bg-white`}
                      >
                        <button onClick={() => setInputValue(prevValue => prevValue - 1)} 
                          className={`w-5 h-5 flex items-center justify-center cursor-pointer ${product.quantity === 1 ? 'opacity-50 !cursor-not-allowed' : ''}`}
                          disabled={inputValue === 1}
                        >
                          -
                        </button> 
                        <input
                          type="number"
                          value={inputValue}
                          onChange={e => handleInputChange(e, product,product)}
                          className="w-9 text-center appearance-none bg-transparent text-[#777] border-none outline-none hover:outline-none focus:outline-none qty-input"
                        />
                        <div onClick={() => setInputValue(prevValue => prevValue + 1)}  className="w-5 h-5 flex items-center justify-center cursor-pointer">
                          +
                        </div>
                      </div>
                    <div className="flex gap-2 items-center">
                      <button
                        className={`relative w-full max-w-max cursor-pointer transition-all duration-300 border border-LightBlue flex justify-center items-center gap-2 text-xs sm:text-base h-7 sm:h-10 px-2 sm:px-3 ${
                          onCheck(product)
                            ? "bg-LightBlue text-white"
                            : "bg-white text-LightBlue"
                        }`}
                        onClick={() => {
                          handleAddToCart(product,inputValue);
                        }}
                      >
                        Add
                        <HiShoppingCart />
                      </button>
                    </div>
                  </div>
                )
              ) : (
                <div className="flex gap-2 items-center">
                  <Skeleton height={36} width={102} />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};
