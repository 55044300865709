import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeWidgetProduct, selectWidgetProducts, setSelectWidgetProducts, setWidgetProductsTotal } from "../../store/widgetCart";
import { openSheet } from "../../store/sheetToggler";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { BASKET_CACHE_KEY, HandleLocalStorage } from "../../utils/handleLocalStorage";
import { selectSelectedCurrency } from "../../store/user";

export default function useCart() {
    const dispatch = useDispatch();
    const selectedWidgetProducts = useSelector(selectWidgetProducts);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState(selectedCurrencyStore);
    const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
    const [basketStates, setBasketStates] = useState(() => {
        const initialBasket = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
        return initialBasket; 
      });
      
      const updateBasketState = () => {
        const updatedBasket = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
        
        // Only update if the basket has actually changed (to avoid unnecessary re-renders)
        if (JSON.stringify(updatedBasket) !== JSON.stringify(basketStates)) {
          setBasketStates(updatedBasket);
        }
      };
      
      useEffect(() => {
        const handleStorageChange = (event) => {
          if (event.key === BASKET_CACHE_KEY) {
            updateBasketState();
          }
        };
      
        const handleLocalStorageChange = () => {
          updateBasketState();
        };
      
        window.addEventListener('storage', handleStorageChange);
        handleLocalStorageChange(); 
        window.addEventListener('localStorageUpdated', handleLocalStorageChange);
      
        return () => {
          window.removeEventListener('storage', handleStorageChange);
          window.removeEventListener('localStorageUpdated', handleLocalStorageChange);
        };
      }, [basketStates]);
      
    const [selectedCartProducts, setSelectedCartProducts] = useState({
        products: [],
        total: 0,
    });

    const filterOutBasket = (updatedBasket) => {
        let tempBasket = { ...updatedBasket };
        tempBasket.products = tempBasket.products.filter(item => item.quantity !== 0);
        setBasketStates(tempBasket);
        HandleLocalStorage(tempBasket);
      };
      

    const handleSelectedGiving = () => {
        let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
        let tempSelectedGiving = [];
        let tempTotalGiving = 0;

        selectedWidgetProducts.products.length &&
            selectedWidgetProducts.products.map((item, index) => {
                basketStatesFromLocalStorage &&
                    basketStatesFromLocalStorage.products.map(innerItem => {
                        if (innerItem.product && innerItem.product.creator === item.creator) {
                            item = Object.assign({}, item, {
                                isAdded: true,
                                quantity: innerItem.quantity,
                            });
                            tempTotalGiving += (item.quantity || 1) * item[selectedCurrencyStore.toLowerCase()];
                            tempSelectedGiving.push(item);
                        }
                        return innerItem;
                    });
                return item;
            });
        setSelectedCartProducts({ ...selectedWidgetProducts, products: tempSelectedGiving, total: tempTotalGiving });
        dispatch(setWidgetProductsTotal(tempTotalGiving));
    };

    const handleAddToGiving = (prd, cartValue, ...rest) => {
        let quantity;

        // If cartValue is "custom", treat it as if it's in rest instead
        const isCustom = cartValue === 'custom' || rest.includes('custom');
    
        if (isCustom) {
            quantity = prd.quantity; // Highest priority
        } else if (cartValue !== undefined && cartValue !== null) {
            quantity = cartValue; // Second priority
        } else if (prd.aud <= 1) {
            quantity = 200; // Third priority
        } else {
            quantity = prd.quantity; // Default case
        }

        let tempPrd = Object.assign({}, prd, {
            quantity: quantity || 1,
        });
        console.log('setSelectWidgetProducts line 135:', prd);
        dispatch(setSelectWidgetProducts({ ...tempPrd, landing_page: window.location.pathname === '/' ? '/home' : window.location.pathname }));
        addProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true });
        handleSelectedGiving();
        if (!localStorage.getItem('isCartSheetShown')) {
            dispatch(openSheet());
            localStorage.setItem('isCartSheetShown', 'true');
        }
    };

    const handleRemoveProduct = prd => {
        removeProduct(prd);
        handleSelectedGiving();
        dispatch(removeWidgetProduct(prd.creator));
    };

    const handleReduceQty = (prd, index) => {
        let quantity = 1;
        // For quantities of 1 or less, remove the product
        if (prd.quantity <= 1) {
            handleRemoveProduct(prd);
        } else {
            // Otherwise reduce the quantity by 1
            let tempPrd = Object.assign({}, prd, {
                quantity: -quantity,
            });
            editProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true });
            dispatch(setSelectWidgetProducts(tempPrd));
            filterOutBasket(JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)));
            handleSelectedGiving();
        }
    };

    const handleAddQty = (prd) => {
        console.log('prdprd add',prd)
        let quantity = 1;
        let tempPrd = Object.assign({}, prd, {
            quantity: quantity,
        });
        editProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true });
        dispatch(setSelectWidgetProducts(tempPrd));
        filterOutBasket(JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)));
        handleSelectedGiving();
    };
    
    const handleUpdateQty = (prd, newQuantity,parentProduct) => {

        if (newQuantity > parentProduct.quantity) {
            let tempPrd = Object.assign({}, prd, {
                quantity: newQuantity - parentProduct.quantity,
            });
            editProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true });
            dispatch(setSelectWidgetProducts(tempPrd));
        } else if (newQuantity < parentProduct.quantity) {
            const decrementQty = parentProduct.quantity - newQuantity;
            let tempPrd = Object.assign({}, prd, {
                quantity: -decrementQty,
            });
            if (newQuantity > 0) {
                editProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true });
                dispatch(setSelectWidgetProducts(tempPrd));
            } else {
                handleRemoveProduct(prd);
            }
        }

        filterOutBasket(JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)));
        handleSelectedGiving();
    };

    const handleCheckProduct = item =>
        selectedCartProducts.products.length > 0 && selectedCartProducts.products.some(product => product.creator === item.creator);

    const handleCurrency = (currency, tempBasket) => {
        if (tempBasket.products.length && tempBasket.products[0].product) {
            tempBasket.products.map(item => {
                item.currency = currency;
                item.product.currency = currency;
                item.total = item.quantity * item.product[currency];
                item.amount = item.product[currency];
                return item;
            });
            tempBasket.currency = currency;
            HandleLocalStorage(tempBasket);
        }
        else {
            tempBasket.currency = currency;
            HandleLocalStorage(tempBasket);
        }
    };

    useEffect(() => {
        let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
        if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.currency !== selectedCurrency) {
            handleCurrency(selectedCurrency, basketStatesFromLocalStorage);
        }
        // if (cartProducts[0]) {
        handleSelectedGiving();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrency, selectedWidgetProducts]);

    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);

    return {
        selectedWidgetProducts,
        selectedCartProducts,
        handleSelectedGiving,
        handleAddToGiving,
        handleRemoveProduct,
        handleReduceQty,
        handleAddQty,
        handleUpdateQty,
        handleCheckProduct,
        basketStates,
        setBasketStates
    }

}
