import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import VideosSection from '../../components/general/videosSection';
import { selectProducts } from '../../store/products';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';
import { OurPromiseSection } from '../../components/general';
import Accordion from '../../components/general/accordion';
import SpecialCasesTab from './specialCasesTabs';
import ImagesSection from '../crisis-and-emergencies/components/common-sections/images';
import specialCasesSlider1 from '../../images/landings/special-cases/gallery-image-1.jpg';
import specialCasesSlider2 from '../../images/landings/special-cases/gallery-image-2.jpg';
import specialCasesSlider3 from '../../images/landings/special-cases/gallery-image-3.jpg';
import { useTranslation } from 'react-i18next';
import { specialCasesFaqs } from './faq';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

export const videos = [
  {
    link: 'https://www.youtube.com/embed/i4iwUlD36uo',
    title: '',
  },
  {
    link: 'https://www.youtube.com/embed/zqq4p3Khbw8',
    title: '',
  },
  {
    link: 'https://www.youtube.com/embed/_Zle84F3Gdc',
    title: '',
  },
];

const SpecialCasesLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [specialCasesProducts, setSpecialCasesProducts] = useState([0, 0, 0]);

  const images = [
    { src: specialCasesSlider1, alt: 'Special Cases' },
    { src: specialCasesSlider2, alt: 'Special Cases' },
    { src: specialCasesSlider3, alt: 'Special Cases' },
  ];
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    const tempSpecialCasesProducts = [];
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'SPC-PRG-24-01') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Special Cases Program Fund' });
            tempSpecialCasesProducts[0] = inner_item_product;
          } else if (inner_item_product.creator === 'SPC-PRG-24-04') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Hope Line Medical Support', suggestedPrices: [50, 100, 250] });
            tempSpecialCasesProducts[1] = inner_item_product;
          } else if (inner_item_product.creator === 'SPC-PRG-24-07') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Home of Hope', suggestedPrices: [50, 100, 250] });
            tempSpecialCasesProducts[2] = inner_item_product;
          }
        });
      });
    });
    setSpecialCasesProducts(tempSpecialCasesProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <NewHelmetLayout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['special-cases']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['special-cases']['description']}`} />
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}

      <section className="xs:block md:hidden sticky top-16 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>

      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="xs:hidden md:block sticky top-16 lg:top-24 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <SpecialCasesTab specialCasesProducts={specialCasesProducts} lang={lang} />
      {/* ----------------- videos section ---------------- */}
      <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
      {/* ----------------- videos section --END-------------- */}

      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1
          className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'
            }`}
        >
          <span className="text-[#f60362] mr-1">MATW Special Cases:</span> A Heartfelt Appeal for Change
        </h1>
        <div
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'
            }`}
        >
          Imagine a world where survival is all that matters. A world where the most basic necessities feel like distant dreams.
          What if we told you that this is a reality for millions living in poverty around the globe? If daily meals and sustenance
          are out of reach, how could they afford any specific medical care, or shelter?
          <strong className='mx-0.5'>MATW’s Special Case Program</strong> is dedicated
          to reaching those who have nowhere else to turn.
          <br />
          <br />
          Through this program, our team identifies individuals whose situations are so dire, they need immediate intervention.
          From urgent surgeries, to home relocation and more, our team makes the most of your Zakat and Sadaqah Jariyah to ensure
          each case is given the support they need
          <br />
          <br />
          Donate Now and help us provide them with the urgent support they need.
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection images={images} />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h1 className="text-[#253B7E] md:text-center mb-5 md:mb-6 lg:mb-8 text-[30px] sm:text-[48px] font-montserratBold">
          Special Cases Program <span className="text-[#F60362]"> FAQ’s</span>
        </h1>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{specialCasesFaqs[0].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{specialCasesFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{specialCasesFaqs[1].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{specialCasesFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{specialCasesFaqs[2].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{specialCasesFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {specialCasesFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>

      {/* /* -------------------------------------------------------------------------- */}
    </NewHelmetLayout>
  );
};
export default SpecialCasesLandingPage;
