import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ShowCurrencies from '../../utils/showCurrencies';
import { IoCloseOutline } from 'react-icons/io5';
import useHandleAddProduct from '../../utils/handleAddProduct';
import getSymbolFromCurrency from 'currency-symbol-map';
import useCart from '../../hooks/cart/useCart';
import { BASKET_CACHE_KEY } from '../../utils/handleLocalStorage';

const countVisualHandler = (isVisible, onChange, onAddQty, onReduceQty, item) => {

    let creator = item.product?.creator;
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY))
  
    const uniqueProducts = basketStatesFromLocalStorage?.products.reduce((acc, item) => {
      const creator = item.product?.creator;
      if (creator && !acc[creator]) {
          acc[creator] = item;
      }
      return acc;
  }, {});
  
    const specificProduct = uniqueProducts[creator];

  return (
  <div
    className={`${isVisible} py-[.2rem] border-[.1rem] border-[#d2d5d999] hover:border-[#25b9db] focus:border-[#25b9db] w-[92px] text-[#777] bg-white`}
  >
    <button onClick={() => onReduceQty()} className="w-5 h-5 flex items-center justify-center cursor-pointer">
      -
    </button>
    <input
      type="number"
      value={item.quantity}
      onChange={e => onChange(e, item)}
      className="w-12 text-center bg-transparent text-[#777] border-none outline-none hover:outline-none focus:outline-none qty-input"
    />
    <div onClick={() => onAddQty()} className="w-5 h-5 flex items-center justify-center cursor-pointer">
      +
    </div>
    {/* <span>{JSON.stringify(item)}</span> */}
  </div>
)};

export const ProductBoxCartSheet = ({ product, onAddQty, onReduceQty, onUpdateQty, onRemove }) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const { handleRemoveProduct } = useCart()
  const { handleUpdateQty } = useCart()
  const {name,image_link} = product ? product.product : '';

  const isMinQuantity = product.quantity <= 1;
  
  
  const handleInputChange = (e, item,parentProduct) => {
    const value = e.target.value.trim();
    const quantity = isNaN(value) || value === '' || Number(value) <= 0 ? 1 : Math.floor(Number(value));
    console.log('quantity inside input', quantity)
    handleUpdateQty(item, quantity,parentProduct);
  };

  return (
    <div className="p-5 bg-[#ebf9fc] shadow-[3px_4px_1px_rgba(0,0,0,0.1)]">
        {/* <div className="product-card p-3 bg-green-500 m-3">
             <h3>{product?.creator}</h3>
             <p>Total: {product?.total}</p>
             <button onClick={() => removeProduct(product)}>Remove</button>
         </div> */}
         {/* {JSON.stringify(product)} */}
         <div className="flex flex-col">
          <div className="flex justify-between gap-2 items-start">
            {/* Left */}
            <div className="flex gap-2 items-start flex-1">
              <img src={image_link} alt="" className="w-16" />
              <div className="flex flex-col gap-1 max-w-[160px]">
                <p className="font-signika text-[19px] md:text-2xl font-semibold text-[#0b3741] !leading-[25px]">{name}</p>
                {/* <p className="font-signika font-light text-[#0b3741] truncate">{item.description}</p> */}
                <button
                    className="font-semibold font-signika text-[#F60362] text-xs flex items-center gap-1 leading-3 uppercase"
                    onClick={() => {
                      handleRemoveProduct(product.product)
                    }}
                  >
                    remove <IoCloseOutline className="text-lg" />
                </button>
              </div>
            </div>
            {/* Right */}
            <div className="flex justify-between items-center">
              <div className="flex flex-col items-end gap-1">
                <p className="font-signika text-[#25b9db] font-bold text-2xl md:text-3xl pr-[2px]">
                  {/* {ShowCurrencies(selectedCurrencyStore, product.total[selectedCurrencyStore.toLowerCase()], false)} */}
                  {!["SGD", "MYR"].includes(selectedCurrencyStore) ? getSymbolFromCurrency(selectedCurrencyStore) : ""}{product.total}
                </p>
                <div
                    className={`flex py-[.2rem] border-[.1rem] border-[#d2d5d999] hover:border-[#25b9db] focus:border-[#25b9db] w-[92px] text-[#777] bg-white`}
                  >
                    <button onClick={() => onReduceQty()} 
                      className={`w-5 h-5 flex items-center justify-center cursor-pointer ${product.quantity === 1 ? 'opacity-50 !cursor-not-allowed' : ''}`}
                      disabled={isMinQuantity}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={product.quantity}
                      onChange={e => handleInputChange(e, product.product,product)}
                      className="w-12 text-center appearance-none bg-transparent text-[#777] border-none outline-none hover:outline-none focus:outline-none qty-input"
                    />
                    <div onClick={() => onAddQty()} className="w-5 h-5 flex items-center justify-center cursor-pointer">
                      +
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  );
};

