// components/Summary.jsx
import React from 'react';
import PropTypes from 'prop-types';
import ShowCurrencies from '../../../utils/showCurrencies';
import CustomButton from '../../general/button';
import { useNavigate } from 'react-router-dom';

const Summary = ({
  selectedProducts,
  numberOfMultiplierNights,
  numberOfNights,
  selectedStrategies,
  totalAmount,
  currency,
  viewCalendar,
  selectedConditions,
  donationOption
}) => {
  const navigate = useNavigate();

  // const MINIMUM_DONATION = 300; // Define the minimum donation amount
  // const MINIMUM_DONATION = numberOfNights * 10; // Define the minimum donation amount
  const MINIMUM_DONATION = currency.toLowerCase() === 'myr' ? 200 : 50; // Define the minimum donation amount

  // Determine if the total amount meets the minimum requirement
  const isMinimumMet = totalAmount >= MINIMUM_DONATION;

  return (
    <div className="w-full bg-[#14A2DC] text-white rounded-md p-4 md:p-8">
      {/* 1. Your Selected Appeals */}
      <h1 className="text-2xl md:text-3xl font-semibold mb-4 pb-4 border-b border-white">Donation Summary</h1>
      <div className="mb-6 pb-4 border-b border-white">
        <h2 className="text-xl font-semibold mb-2">Your selected appeals:</h2>
        {selectedProducts.length > 0 ? (
          <ul className="list-none">
            {selectedProducts.map((product) => (
              <li key={product.id} className="flex justify-between items-center">
                <span className='font-thin text-sm'>{product.name}</span>
                <span className='font-bold text-lg'>{ShowCurrencies(currency, product.quantity * product[currency.toLowerCase()])}</span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="">No appeals selected.</p>
        )}
      </div>

      {/* 2. Your Steps to Jannah Schedule */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Your Steps to Jannah Schedule:</h2>
        <p className="">Number of payments: {numberOfNights}</p>
      </div>

      {/* 3. Special Requests */}
      <div className="mb-6 pb-4 border-b border-white">
        <h2 className="text-lg font-semibold mb-2">Special Requests:</h2>
        {selectedStrategies.length > 0 ? (
          <ul className="list-disc list-inside">
            {selectedStrategies.map((strategy, index) => (
              <li key={index} className="mb-1">
                {strategy.nights
                ? <>Give <strong>x{strategy.multiplier}</strong> on <strong>{strategy.nights} night{strategy.nights > 1 ? 's' : ''}</strong> {strategy.label}.</>
                : <>Give <strong>x{strategy.multiplier}</strong> {strategy.label}.</>
                }
              </li>
            ))}
          </ul>
        ) : (
          <p className="">No special requests selected.</p>
        )}
      </div>
      <div className="mb-6 pb-4 border-b border-white">
        <h3 className="cursor-pointer mb-2 underline" onClick={() => viewCalendar()}>View your donation calendar</h3>
      </div>
      {/* 4. Total Amount */}
      <div className='flex justify-between items-center mb-6'>
        <h2 className="text-xl font-semibold">Total Amount:</h2>
        <div className="text-2xl font-bold">
          {ShowCurrencies(currency, totalAmount)}
        </div>
      </div>

      {/* 5. Minimum Donation Warning */}
      {!isMinimumMet ? (
        <div className="mb-4 p-2 bg-red-500 text-white rounded-md">
          <p className="text-sm [&>div]:inline-block">
            The minimum total donation amount is {ShowCurrencies(currency, MINIMUM_DONATION)}. Please adjust your selections to meet this requirement.
          </p>
        </div>
      )
        : donationOption === 'custom' && !selectedConditions.length
          ? <div className="mb-4 p-2 bg-red-500 text-white rounded-md">
            <p className="text-sm [&>div]:inline-block">
              Please select at least one request
            </p>
          </div>
          : ''
      }
  {/* {console.log("options=",donationOption,selectedConditions, selectedConditions.length)} */}
      {/* 6. Donate Now Button */}
      <CustomButton
        title="Donate Now"
        className={`mt-4 w-full hover:!bg-[#f60362] ${!isMinimumMet ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={() => {
          if (isMinimumMet) {
            localStorage.setItem('IS_DAILY_DONATION', JSON.stringify(true));
            navigate("/checkout");
          }
        }}
        disabled={!isMinimumMet ? true : (donationOption === 'custom' && !selectedConditions.length) ? true : false} // Disable the button if minimum is not met
      />
    </div>
  );
};

// Updated PropTypes for multiple strategies
Summary.propTypes = {
  selectedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired, // Support string IDs if necessary
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  numberOfMultiplierNights: PropTypes.number.isRequired,
  numberOfNights: PropTypes.number.isRequired,
  selectedStrategies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      multiplier: PropTypes.number.isRequired,
      nights: PropTypes.number.isRequired, // Number of nights this strategy applies to
    })
  ).isRequired,
  totalAmount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Summary;
