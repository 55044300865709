import React, { useState, useEffect } from 'react';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import CustomButton from '../button';
import SelectCurrency from '../selectCurrency';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import ShowCurrencies from '../../../utils/showCurrencies';

function IftarDonationWidget(props) {
  const {
    topList = [],
    bottomList = [],
    cardTitle = '',
    cardSubTitle = '',
    topCardTitle = '',
    topCardImageBlue = '',
    topCardProduct = '',
    buttonLable = 'Donate Now',
  } = props;
  const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  // const [currentCurrency, setCurrentCurrency] = useState('AUD');
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [topListState, setTopListState] = useState([]);
  const [columns, setColumns] = useState([]);
  const [focusedCardId, setFocusedCardId] = useState(null);
  const [openDescriptionId, setOpenDescriptionId] = useState(null);

  const navigate = useNavigate();
  // State for total amount
  const [totalAmount, setTotalAmount] = useState(0);

  // State for quantities (for items in topList and bottomList)
  const [quantities, setQuantities] = useState(() => {
    const initialQuantities = {};
    [...topList, ...bottomList].forEach(item => {
      initialQuantities[item.id] = { quantity: 0, selected: false };
    });
    return initialQuantities;
  });

  const saveSelectedProducts = products => {
    if (products.length === 0) {
      localStorage.removeItem('selectedProducts');
    } else {
      localStorage.setItem('selectedProducts', JSON.stringify(products));
    }
  };

  const updateSelectedProducts = quantities => {
    const selectedProducts = Object.entries(quantities)
      .filter(([_, value]) => value.selected)
      .map(([key, value]) => ({
        id: key,
        quantity: value.quantity,
      }));

    saveSelectedProducts(selectedProducts);
  };

  const handleSelectCard = itemId => {
    setFocusedCardId(itemId);
  };

  const handleBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocusedCardId(null);
    }
  };
  const handleAddQty = (prd, index) => {
    let tempPrd = { ...prd, quantity: 1 };
    addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

    setQuantities(prev => {
      const updatedQuantities = {
        ...prev,
        [prd.id]: {
          ...prev[prd.id],
          quantity: (prev[prd.id]?.quantity || 0) + 1,
          selected: true,
        },
      };

      updateSelectedProducts(updatedQuantities);
      return updatedQuantities;
    });
  };

  const handleReduceQty = (prd, index) => {
    const currentQty = quantities[prd.id]?.quantity || 0;

    if (currentQty > 1) {
      let tempPrd = { ...prd, quantity: -1 };
      editProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

      setQuantities(prev => {
        const updatedQuantities = {
          ...prev,
          [prd.id]: {
            ...prev[prd.id],
            quantity: currentQty - 1,
            selected: true,
          },
        };

        updateSelectedProducts(updatedQuantities);
        return updatedQuantities;
      });
    } else {
      handleRemoveProduct(prd);
      setQuantities(prev => {
        const updatedQuantities = { ...prev };
        delete updatedQuantities[prd.id];

        updateSelectedProducts(updatedQuantities);
        return updatedQuantities;
      });
    }
  };

  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };

  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];

    topListState.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });

    setTopListState(temp);
  };

  const handleCheckboxChange = prd => {
    setQuantities(prevQuantities => {
      const newQuantities = { ...prevQuantities };
      const isSelected = !newQuantities[prd.id]?.selected;

      if (isSelected) {
        let tempPrd = { ...prd, quantity: 1 };
        addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

        newQuantities[prd.id] = {
          quantity: 1,
          selected: true,
        };
      } else {
        handleRemoveProduct(prd);
        newQuantities[prd.id] = {
          quantity: 0,
          selected: false,
        };
      }

      updateSelectedProducts(newQuantities);
      return newQuantities;
    });
  };

  const toggleDescription = id => {
    setOpenDescriptionId(openDescriptionId === id ? null : id);
  };

  // Handle currency change
  // const handleCurrency = currency => {
  //   setCurrentCurrency(currency.value);
  // };

  const handleDonateNow = () => {
    navigate('/checkout');
  };

  const currAmmount = (currencyCode, amount) => {
    return `${getSymbolFromCurrency(currencyCode.toLowerCase())}${amount}`;
  };

  useEffect(() => {
    setTopListState(topList);
  }, [topList, selectedCurrencyStore]);

  useEffect(() => {
    const half = Math.ceil(topListState.length / 2);
    setColumns([topListState.slice(0, half), topListState.slice(half)]);
  }, [topListState]);

  useEffect(() => {
    const grandTotal = basketStatesFromLocalStorage.grandTotal;
    setTotalAmount(grandTotal ?? 0);
  }, [handleCheckboxChange, handleSelectedGiving]);

  useEffect(() => {
    const local = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.products && basketStatesFromLocalStorage.products[0].amount === 0) {
      localStorage.removeItem('selectedProducts');
      return;
    }

    setQuantities(prevQuantities => {
      const updatedQuantities = { ...prevQuantities };
      local.forEach(item => {
        const productId = item.id;
        updatedQuantities[productId] = {
          quantity: item.quantity,
          selected: true,
        };
      });

      return updatedQuantities;
    });
  }, []);

  return (
    <div className="bg-white flex flex-col items-center justify-center md:p-4" tabIndex={-1} onBlur={handleBlur}>
      <div className="bg-transparent shadow-lg p-2 md:p-4 w-full md:w-[648px] min-h-[679px] max-w-full mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-primaryDark mb-4">{cardTitle}</h2>
          <div dangerouslySetInnerHTML={{__html: cardSubTitle}}/>
          <div
              className=" hidden w-full h-[84px] bg-[#CDF1FF] mb-4 flex justify-center items-center relative rounded-lg shadow-lg">

            <div
                className="absolute inset-0 bg-no-repeat bg-center bg-cover opacity-30 rounded-lg"
                style={{
                  backgroundImage:
                      'url("https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2Fdd9ecd54a0cf4fd58721886fa4925044")',
                }}
            ></div>

            <div className="relative z-10 flex items-center">
              <h2 className="text-[18px] font-bold text-primaryDark ml-2">30 steps to Jannah: Signup to give daily in
                Ramadan now</h2>
            </div>
          </div>
          {/* TopList: Package Plus single */}
          {topCardProduct.map(item => {
            const quantityValue = quantities[item.id]?.quantity || 0;
            const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

            return (
                <div
                    key={item.id}
                    className={`border rounded-lg h-[84px] justify-between flex items-center px-1 shadow-md

                  ${'bg-[#CDF1FF]'}`}
                    onClick={e => {
                      e.stopPropagation();
                      handleSelectCard(item.id);
                    }}
                    tabIndex={0}
                    onBlur={handleBlur}
                >
                  <div className="flex items-center w-full justify-between">
                    {/* Title Section */}
                    <div className="flex ml-1 ">
                      <h3 className={`font-brandingBold text-[16px] sm:text-[16px] ${'text-primaryDark'} leading-[12px] text-left decoration-[1px]`}>
                        {topCardTitle}
                      </h3>
                    </div>

                    {/* Image and Product Name Section */}
                    <div className="flex items-center">
                      <div className="flex items-center flex-col">
                        <img className="w-[80px] h-[37px] rounded" src={topCardImageBlue} alt="Orphans_Food"/>
                        <p className={`font-brandingBold text-[13px] ${'text-primaryDark'} leading-[12px] text-center decoration-[1px]`}>{item.name}</p>
                      </div>
                      {/* Price Section */}
                      <div className="flex items-center justify-center space-x-0.5 whitespace-nowrap">
                        <p className={`font-brandingBold text-[16px] ${'text-primaryDark'} leading-[12px] text-center decoration-[1px]`}>
                          {'= '}
                          {currAmmount(selectedCurrencyStore, price)}
                        </p>
                      </div>
                    </div>

                    {/* Quantity Control Section */}
                    <div className="flex items-center justify-end ml-2">
                      {
                        <div className="flex items-center">
                          <button className={`${'text-pink'} text-[20px] font-bold text-lg px-2 `}
                                  onClick={() => handleReduceQty(item)}>
                            -
                          </button>
                          <span
                              className={`${'bg-pink border-pink text-white'} text-[12px]  border rounded-lg px-2 py-0.5`}>{quantityValue}</span>
                          <button className={`${'text-pink'} text-[20px] font-bold text-lg px-2`}
                                  onClick={() => handleAddQty(item)}>
                            +
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
            );
          })}

          {/* TopList: Two-column grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
            {topListState.length === 0 ? (
                <div className="flex justify-center items-center w-[300px] h-[200px]">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#253B7E]"></div>
                </div>
            ) : (
                columns.map((column, colIndex) => (
                    <div key={colIndex} className="space-y-3">
                      {column.map(item => {
                        const quantityValue = quantities[item.id]?.quantity || 0;
                        const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

                        return (
                            <div
                                key={item.id}
                                className={`border ${openDescriptionId === item.id ? '' : 'h-[84px]'} rounded-lg  flex items-center p-1 shadow-md`}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleSelectCard(item.id);
                                }}
                                tabIndex={0}
                                onBlur={handleBlur}
                            >
                              <div className="flex flex-col items-center  w-full ">
                                <div className="flex items-center w-full">
                                  {/* Left Section: Square Image and Product Name */}
                                  <div>
                                    <div className="flex items-center ml-1">
                                      <img src={item.image_link} alt={item.name}
                                           className="w-16 h-16 object-cover mr-2 rounded shadow-md"/>
                                      <h3 className="font-brandingBold text-left text-[20px] sm:text-[14px] text-primaryDark ">{item.name}</h3>
                                      {/* Dropdown Icon */}
                                      <button
                                          onClick={e => {
                                            e.stopPropagation();
                                            toggleDescription(item.id);
                                          }}
                                          className="focus:outline-none"
                                      >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={`h-5 w-5 transform transition-transform ${openDescriptionId === item.id ? 'rotate-180' : ''}`}
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                          <path
                                              fillRule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clipRule="evenodd"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>

                                  {/* Right Section: Price & Quantity Controls */}
                                  <div className="flex items-center justify-end ml-auto">
                                    <div className="flex flex-col items-center ml-2">
                                      <p className="font-brandingBold text-[16px] sm:text-[12px] text-pink border-pink rounded-md border px-1">
                                        {currAmmount(selectedCurrencyStore, Number(price).toLocaleString('en-US', {minimumFractionDigits: 0}))}
                                      </p>
                                    </div>

                                    {
                                      <div className="flex items-center">
                                        <button
                                            className="text-pink text-[25px] font-bold px-2"
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleReduceQty(item, colIndex);
                                            }}
                                        >
                                          -
                                        </button>
                                        <span
                                            className="border border-pink bg-pink text-white text-[14px] rounded-lg px-2 py-0.5">{quantityValue}</span>
                                        <button
                                            className="text-pink text-[25px] font-bold px-2"
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleAddQty(item, colIndex);
                                            }}
                                        >
                                          +
                                        </button>
                                      </div>
                                    }
                                  </div>
                                </div>
                                {openDescriptionId === item.id && (
                                    <div
                                        className="sm:text-[12px] text-[16px] p-2 text-[#253B7E] font-branding ">{item.short_description || item.description}</div>
                                )}
                              </div>
                            </div>
                        );
                      })}
                    </div>
                ))
            )}
          </div>

          {/* BottomList */}

          <div className="flex flex-col sm:flex-row py-7">
            <div className="w-full sm:w-[70%]">
              {bottomList &&
                  bottomList.map(item => {
                    const quantityValue = quantities[item.id]?.quantity || 0;
                    const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

                    return (
                        <div
                            key={item.id}
                            className={`flex w-full md:whitespace-nowrap items-center rounded-md mt-1 ${
                                quantityValue > 0 ? 'border w-fit border-pink' : ''
                            }`}
                        >
                          {/* Product name */}
                          <div className="flex ml-1">
                            <h3 className="font-branding text-[14px] font-medium text-left decoration-[1px] text-textPrimary">{item.name}</h3>
                          </div>

                          {/* Currency container takes up available space and aligns text right */}
                          <div className="flex-1 text-right ml-1">
                            <p className="font-branding text-[14px] decoration-[1px] font-bold text-pink">
                              {currAmmount(selectedCurrencyStore, price)} {selectedCurrencyStore.toUpperCase()}
                            </p>
                          </div>

                          {/* Quantity controls */}
                          <div className="flex items-center justify-center ml-1">
                            <div className="flex items-center">
                              <button className="text-[#797979] text-[14px] font-bold text-lg px-2"
                                      onClick={() => handleReduceQty(item)}>
                                -
                              </button>
                              <span
                                  className="text-[10px] text-[#797979] border border-[#797979] rounded-md px-1 py-0.5">{quantityValue}</span>
                              <button className="text-[#797979] text-[14px] font-bold text-lg px-2"
                                      onClick={() => handleAddQty(item)}>
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                    );
                  })}
            </div>
            <div className="w-full sm:w-[30%] flex justify-end mt-4 sm:mt-0">
              <div
                  className="w-full h-[90px] sm:w-[112px] sm:h-[79px] rounded-lg bg-[#F6036212] bg-opacity-30 flex flex-col items-center justify-center">
                <p className="text-textPrimary font-bold text-[14px]">Total Amount</p>
                <p className="text-pink text-sm mt-1 text-[18px] font-bold">
                  {currAmmount(selectedCurrencyStore, Number(totalAmount).toLocaleString('en-US', {minimumFractionDigits: 0}))} {selectedCurrencyStore.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="flex ">
            <div className="w-[30%] z-[31]">
              <SelectCurrency onChangeCurrency={e => {
              }} classNamePrefix="home_select"/>
            </div>
            <div className="w-[70%] flex justify-end">
              <CustomButton
                  title={'Donate Now'}
                  onClick={handleDonateNow}
                  className="bg-pink w-[90%] text-white rounded-full hover:bg-primaryDark text-xs w-[30px] h-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IftarDonationWidget;
