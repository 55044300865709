// components/RamadanStartDateSelector.jsx
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { FiCalendar } from 'react-icons/fi';
import { DateTime } from 'luxon';

const RamadanStartDateSelector = ({ setRamadanStartDate, timeZone, ramadanStartDate }) => {
  // Define fixed min and max dates
  const fixedMinDate = DateTime.fromISO('2025-02-27', { zone: timeZone }).toJSDate();
  const fixedMaxDate = DateTime.fromISO('2025-03-02', { zone: timeZone }).toJSDate();

  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full z-[11]">
      <label className='text-stone-500 flex items-center'>
        <span className='text-red-600 mr-1'>*</span>Your Ramadan Start Date
      </label>
      <div
        onClick={() => setIsOpen(true)}
        className={`cursor-pointer flex h-12 relative rounded-md items-center justify-center border border-[#282828]`}
      >
        {!ramadanStartDate && (
          <div className={`absolute z-[10] flex items-center justify-between w-full px-4`}>
            <div className="text-[#1E3271]">Select Date</div>
            <div className="text-[#1E3271]">
              <FiCalendar size={20} />
            </div>
          </div>
        )}
        <div ref={datePickerRef} className="w-full h-full">
          <DatePicker
            onChange={(date) => {
              if (date) {
                // Ensure the selected date is within the fixed range
                const selectedDate = DateTime.fromJSDate(date, { zone: timeZone });
                const minDateDT = DateTime.fromJSDate(fixedMinDate, { zone: timeZone }).startOf('day');
                const maxDateDT = DateTime.fromJSDate(fixedMaxDate, { zone: timeZone }).endOf('day');

                if (selectedDate < minDateDT) {
                  // Prevent selecting a date before minDate
                  setRamadanStartDate(minDateDT.toISODate());
                } else if (selectedDate > maxDateDT) {
                  // Prevent selecting a date after maxDate
                  setRamadanStartDate(maxDateDT.toISODate());
                } else {
                  setRamadanStartDate(selectedDate.toISODate());
                }
              } else {
                // Clear the selection
                setRamadanStartDate(null);
              }
            }}
            isClearable={true}
            selected={ramadanStartDate ? new Date(ramadanStartDate) : null}
            className="red-border w-full px-2 h-full rounded-md"
            minDate={fixedMinDate}
            maxDate={fixedMaxDate}
            open={isOpen}
            onCalendarClose={() => setIsOpen(false)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
      </div>
    </div>
  );
};

RamadanStartDateSelector.propTypes = {
  setRamadanStartDate: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  ramadanStartDate: PropTypes.string, // Optional, can be null
};

export default RamadanStartDateSelector;
