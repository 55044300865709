// // utils/calculationUtils.js
// import { DateTime } from 'luxon';

// /**
//  * Calculates the donation schedule based on the selected option and conditions.
//  * @param {Object} params
//  * @param {string} params.startDate - The adjusted start date of donation in ISO format.
//  * @param {string} params.timeZone - The user's time zone.
//  * @param {Array} params.selectedProducts - Array of selected products with quantities.
//  * @param {string} params.donationOption - 'equal' or 'custom'.
//  * @param {Array} params.selectedConditions - Array of selected special condition values.
//  * @param {Object} params.multipliers - Multiplier values for each condition.
//  * @param {string} params.currency - The selected currency.
//  * @param {number} params.numberOfNights - The number of nights to donate.
//  * @returns {Object} An object containing the donation schedule and any difference due to rounding.
//  */
// export const calculateDonations = ({
//   startDate,
//   timeZone,
//   selectedProducts,
//   donationOption,
//   selectedConditions,
//   multipliers,
//   currency,
//   numberOfNights,
// }) => {
//   // Convert startDate to Luxon DateTime in specified timezone
//   const startDateLuxon = DateTime.fromISO(startDate, { zone: timeZone }).startOf('day');

//   // Calculate exact totalAmount based on currency, price, and quantity
//   const exactTotalAmount = selectedProducts.reduce((sum, p) => {
//     const price = p[currency.toLowerCase()];
//     const quantity = p.quantity || 0;
//     if (typeof price === 'number') {
//       return sum + price * p.quantity;
//     }
//     return sum;
//   }, 0);

//   // Round the totalAmount to the nearest integer
//   const totalAmountRounded = Math.round(exactTotalAmount);

//   // Generate donation dates based on the number of nights
//   const donationDates = [];
//   for (let i = 0; i < numberOfNights; i++) {
//     donationDates.push(startDateLuxon.plus({ days: i }));
//   }

//   if (donationOption === 'equal') {
//     // Equal Division Logic
//     const perDayAmount = totalAmountRounded / numberOfNights;
//     const donationSchedule = donationDates.map((date) => ({
//       date: date.toISODate(),
//       amount: Math.round(perDayAmount),
//       isMultiplierNight: false,
//       products: [],
//       hasProduct: false,
//       remainedAmount: 0,
//       shares: 1, // Base share
//     }));

//     // Adjust for rounding differences
//     const sumRounded = donationSchedule.reduce((sum, day) => sum + day.amount, 0);
//     let difference = totalAmountRounded - sumRounded;

//     const adjustedDonationSchedule = donationSchedule.map((day, index) => {
//       if (difference > 0 && index < difference) {
//         return { ...day, amount: day.amount + 1 };
//       } else if (difference < 0 && index < Math.abs(difference)) {
//         return { ...day, amount: day.amount - 1 };
//       }
//       return day;
//     });

//     return {
//       donationSchedule: adjustedDonationSchedule,
//       difference: totalAmountRounded - adjustedDonationSchedule.reduce((sum, day) => sum + day.amount, 0),
//     };
//   } else if (donationOption === 'custom') {
//     // Custom Conditions Logic

//     if (selectedConditions.length === 0) {
//       throw new Error('At least one special condition must be selected for custom donations.');
//     }

//     // Initialize shares for each day
//     const dayShares = donationDates.map((date) => ({
//       date: date.toISODate(),
//       shares: 1, // Base share
//       isMultiplierNight: false,
//     }));

//     // Determine the last ten nights
//     const lastTenNightsStartIndex = Math.max(0, numberOfNights - 10);
//     const lastTenNightsIndices = [];
//     for (let i = lastTenNightsStartIndex; i < numberOfNights; i++) {
//       lastTenNightsIndices.push(i);
//     }

//     // Apply special condition shares based on selected multipliers
//     selectedConditions.forEach((condition) => {
//       switch (condition) {
//         case 'friday':
//           dayShares.forEach((day, index) => {
//             const dateObj = DateTime.fromISO(day.date, { zone: timeZone });
//             if (dateObj.weekday === 5) { // Friday
//               day.shares += multipliers.friday;
//               // day.shares = multipliers.friday;
//               day.isMultiplierNight = true;
//             }
//           });
//           break;
//         case 'lastTenNights':
//           dayShares.forEach((day, index) => {
//             if (lastTenNightsIndices.includes(index)) {
//               day.shares += multipliers.lastTenNights;
//               // day.shares = multipliers.lastTenNights;
//               day.isMultiplierNight = true;
//             }
//           });
//           break;
//         case 'oddNights':
//           dayShares.forEach((day, index) => {
//             // Adjusted: Apply odd night multiplier only within the last ten nights
//             if (lastTenNightsIndices.includes(index)) {
//               const nightNumber = index + 1; // Start counting from 1
//               if (nightNumber % 2 !== 0) { // Odd night
//                 day.shares += multipliers.oddNights;
//                 // day.shares = multipliers.oddNights;
//                 day.isMultiplierNight = true;
//               }
//             }
//           });
//           break;
//         case 'laylatulQadr':
//           if (numberOfNights >= 27) {
//             dayShares.forEach((day, index) => {
//               if (index === 26) { // 27th night (0-based index)
//                 day.shares += multipliers.laylatulQadr;
//                 // day.shares = multipliers.laylatulQadr;
//                 day.isMultiplierNight = true;
//               }
//             });
//           }
//           break;
//         default:
//           break;
//       }
//     });

//     // Calculate total shares
//     const totalShares = dayShares.reduce((sum, day) => sum + day.shares, 0);

//     // Calculate share value
//     const shareValue = totalShares > 0 ? exactTotalAmount / totalShares : 0;

//     // Assign amounts based on shares
//     const donationSchedule = dayShares.map((day) => ({
//       date: day.date,
//       amount: Math.round(shareValue * day.shares),
//       isMultiplierNight: day.isMultiplierNight,
//       products: [],
//       hasProduct: false,
//       remainedAmount: 0,
//       shares: day.shares,
//     }));

//     // Calculate the sum of rounded amounts
//     const sumRounded = donationSchedule.reduce((sum, day) => sum + day.amount, 0);

//     // Calculate the difference to reach totalAmountRounded
//     let difference = totalAmountRounded - sumRounded;

//     // Distribute the difference by adding or subtracting 1 from the first 'difference' days
//     const adjustedDonationSchedule = donationSchedule.map((day, index) => {
//       if (difference > 0 && index < difference) {
//         return { ...day, amount: day.amount + 1 };
//       } else if (difference < 0 && index < Math.abs(difference)) {
//         return { ...day, amount: day.amount - 1 };
//       }
//       return day;
//     });

//     // Final sum after adjustment
//     const finalSum = adjustedDonationSchedule.reduce((sum, day) => sum + day.amount, 0);

//     // Calculate final difference (should be zero)
//     const finalDifference = finalSum - totalAmountRounded;
//     console.log('adjustedDonationSchedule=', adjustedDonationSchedule);

//     // Handle product distribution if needed (similar to existing logic)
//     // ...

//     return { donationSchedule: adjustedDonationSchedule, difference: finalDifference };
//   } else {
//     throw new Error('Invalid donation option selected.');
//   }
// };

/* -------------------------------------------------------------------------- */

// utils/calculationUtils.js
// import { DateTime } from 'luxon';

// /**
//  * Calculates the donation schedule based on selected options and special conditions.
//  *
//  * @param {Object} params
//  * @param {string} params.startDate - The first donation date (user-selected Ramadan start date).
//  * @param {string} params.timeZone - The user's time zone.
//  * @param {Array} params.selectedProducts - Array of selected products (each with price, quantity, etc.).
//  * @param {string} params.donationOption - 'equal' or 'custom'.
//  * @param {Array} params.selectedConditions - Array of selected condition strings.
//  *        Allowed values: "friday", "lastTenNights", "oddNights", "laylatulQadr".
//  * @param {Object} params.multipliers - Multipliers for each condition.
//  *        Example: { friday: 3, lastTenNights: 2, oddNights: 2, laylatulQadr: 5 }
//  * @param {string} params.currency - The selected currency code.
//  * @param {number} params.numberOfNights - Total donation nights (typically 30).
//  * @returns {Object} An object containing the donation schedule and any rounding difference.
//  */
// export const calculateDonations = ({
//   startDate,
//   timeZone,
//   selectedProducts,
//   donationOption,
//   selectedConditions,
//   multipliers,
//   currency,
//   numberOfNights,
// }) => {
//   // 1. Calculate the total donation amount based on selected products.
//   const exactTotalAmount = selectedProducts.reduce((sum, p) => {
//     const price = p[currency.toLowerCase()];
//     const quantity = p.quantity || 0;
//     return typeof price === 'number' ? sum + price * quantity : sum;
//   }, 0);
//   const totalAmountRounded = Math.round(exactTotalAmount);

//   // 2. Build an array of donation dates (for 30 nights starting from startDate).
//   const donationDates = [];
//   const startDateLuxon = DateTime.fromISO(startDate, { zone: timeZone }).startOf('day');
//   for (let i = 0; i < numberOfNights; i++) {
//     donationDates.push(startDateLuxon.plus({ days: i }));
//   }

//   // 3. For the custom option, assign each day a "share" value based on the selected conditions.
//   // We'll decide priority in the following order:
//   //    (a) Friday (highest priority)
//   //    (b) Laylatul Qadr (if applicable; 27th night)
//   //    (c) Within the last 10 nights:
//   //         - If "oddNights" is selected and the day’s number is odd, then assign the oddNights multiplier.
//   //         - Otherwise, if "lastTenNights" is selected, assign that multiplier.
//   //    (d) Otherwise, default to 1.
//   const totalNights = donationDates.length;
//   const dayShares = donationDates.map((date, index) => {
//     let share = 1; // default

//     // (a) Friday: if selected and day is Friday.
//     if (selectedConditions.includes('friday') && date.weekday === 5) {
//       share = multipliers.friday;
//     }
//     // (b) Laylatul Qadr: if selected and this is the 27th night (index 26) and not already overridden.
//     else if (selectedConditions.includes('laylatulQadr') && index === 26) {
//       share = multipliers.laylatulQadr;
//     }
//     // (c) For days in the last 10 nights:
//     else if (index >= totalNights - 10) {
//       // Check oddNights first.
//       if (selectedConditions.includes('oddNights') && ((index + 1) % 2 === 1)) {
//         share = multipliers.oddNights;
//       }
//       // If not an odd night (or oddNights not selected) and lastTenNights is selected.
//       else if (selectedConditions.includes('lastTenNights')) {
//         share = multipliers.lastTenNights;
//       }
//     }
//     // (d) Otherwise, keep the default share of 1.
//     return share;
//   });

//   // 4. Compute total shares and determine the per-share value.
//   const totalShares = dayShares.reduce((sum, s) => sum + s, 0);
//   const shareValue = totalShares > 0 ? totalAmountRounded / totalShares : 0;

//   // 5. Build the donation schedule: each day gets an amount = (its share) * (shareValue).
//   const donationSchedule = donationDates.map((date, index) => ({
//     date: date.toISODate(),
//     shares: dayShares[index],
//     amount: Math.round(dayShares[index] * shareValue),
//     isMultiplierNight: dayShares[index] > 1,
//     products: [],
//     hasProduct: false,
//     remainedAmount: 0,
//   }));

//   // 6. Adjust for any rounding differences so that the total equals totalAmountRounded.
//   const sumRounded = donationSchedule.reduce((sum, day) => sum + day.amount, 0);
//   let difference = totalAmountRounded - sumRounded;
//   const adjustedDonationSchedule = donationSchedule.map((day, index) => {
//     if (difference > 0 && index < difference) {
//       return { ...day, amount: day.amount + 1 };
//     } else if (difference < 0 && index < Math.abs(difference)) {
//       return { ...day, amount: day.amount - 1 };
//     }
//     return day;
//   });

//   const finalSum = adjustedDonationSchedule.reduce((sum, day) => sum + day.amount, 0);
//   const finalDifference = finalSum - totalAmountRounded;
//   return {
//     donationSchedule: adjustedDonationSchedule,
//     difference: finalDifference,
//   };
// };

/* -------------------------------------------------------------------------- */

// utils/calculationUtils.js
import { DateTime } from 'luxon';

/**
 * Calculates the donation schedule based on selected options and special conditions.
 *
 * @param {Object} params
 * @param {string} params.startDate - The first donation date (user-selected Ramadan start date).
 * @param {string} params.timeZone - The user's time zone.
 * @param {Array} params.selectedProducts - Array of selected products (each with price, quantity, etc.).
 * @param {string} params.donationOption - 'equal' or 'custom'.
 * @param {Array} params.selectedConditions - Array of selected condition strings.
 *        Allowed values: "friday", "lastTenNights", "oddNights", "laylatulQadr".
 * @param {Object} params.multipliers - Multipliers for each condition.
 *        Example: { friday: 2, lastTenNights: 3, oddNights: 4, laylatulQadr: 5 }
 * @param {string} params.currency - The selected currency code.
 * @param {number} params.numberOfNights - Total donation nights (typically 30).
 * @returns {Object} An object containing the donation schedule and any rounding difference.
 */
export const calculateDonations = ({
  startDate,
  timeZone,
  selectedProducts,
  donationOption,
  selectedConditions,
  multipliers,
  currency,
  numberOfNights,
}) => {
  // 1. Calculate the total donation amount based on selected products.
  const exactTotalAmount = selectedProducts.reduce((sum, p) => {
    const price = p[currency.toLowerCase()];
    const quantity = p.quantity || 0;
    return typeof price === 'number' ? sum + price * quantity : sum;
  }, 0);
  const totalAmountRounded = Math.round(exactTotalAmount);

  // 2. Build an array of donation dates (for the specified number of nights starting from startDate).
  const donationDates = [];
  const startDateLuxon = DateTime.fromISO(startDate, { zone: timeZone }).startOf('day');
  for (let i = 0; i < numberOfNights; i++) {
    donationDates.push(startDateLuxon.plus({ days: i }));
  }
  const totalNights = donationDates.length;

  // 3. For the 'custom' option, assign each day a share value based on all applicable conditions.
  //    We will add the multiplier values for each condition that applies.
  //    If no condition applies, the default share will be 1.
  const dayShares = donationDates.map((date, index) => {
    let share = 0; // start at 0 and add each condition's multiplier if applicable

    // Condition: Friday
    // (If selected and the day is Friday, add the Friday multiplier)
    if (selectedConditions.includes('friday') && date.weekday === 5) {
      share += multipliers.friday;
    }

    // Condition: Last 10 Nights
    // (If selected and the day is within the last 10 nights)
    if (selectedConditions.includes('lastTenNights') && index >= totalNights - 10) {
      share += multipliers.lastTenNights;
    }

    // Condition: Odd Nights (within the last 10)
    // (If selected and the day is one of the last 10 nights and its overall position is odd)
    if (selectedConditions.includes('oddNights') && index >= totalNights - 10 && (index + 1) % 2 === 1) {
      share += multipliers.oddNights;
    }

    // Condition: Laylatul Qadr
    // (If selected and the day is the 27th night (index 26))
    if (selectedConditions.includes('laylatulQadr') && index === 26) {
      share += multipliers.laylatulQadr;
    }

    // If no special condition applies, assign the default share of 1.
    if (share === 0) {
      share = 1;
    }

    return share;
  });

  // 4. Compute total shares and determine the per-share value.
  const totalShares = dayShares.reduce((sum, s) => sum + s, 0);
  const shareValue = totalShares > 0 ? totalAmountRounded / totalShares : 0;
  // console.log("donationDates=",donationDates,dayShares,totalShares,shareValue)
  // 5. Build the donation schedule: each day gets an amount = (its share) * (shareValue).
  const donationSchedule = donationDates.map((date, index) => ({
    date: date.toISODate(),
    shares: dayShares[index],
    amount: Math.round(dayShares[index] * shareValue),
    isMultiplierNight: dayShares[index] > 1,
    products: [],
    hasProduct: false,
    remainedAmount: 0,
  }));

  // 6. Adjust for any rounding differences so that the total equals totalAmountRounded.
  const sumRounded = donationSchedule.reduce((sum, day) => sum + day.amount, 0);
  let difference = totalAmountRounded - sumRounded;
  const adjustedDonationSchedule = donationSchedule.map((day, index) => {
    if (difference > 0 && index < difference) {
      return { ...day, amount: day.amount + 1 };
    } else if (difference < 0 && index < Math.abs(difference)) {
      return { ...day, amount: day.amount - 1 };
    }
    return day;
  });

  const finalSum = adjustedDonationSchedule.reduce((sum, day) => sum + day.amount, 0);
  const finalDifference = finalSum - totalAmountRounded;

  const handleAddPrd = () => {
    const tempadjustedDonationSchedule = JSON.parse(JSON.stringify(adjustedDonationSchedule));
    let _arr = [];
    let _totalPrds = 0;
    selectedProducts.map(prd => {
      let _total = prd.quantity * prd[currency.toLowerCase()];
      _totalPrds += _total;
    });
    selectedProducts.map(prd => {
      let _total = prd.quantity * prd[currency.toLowerCase()];
      _arr.push({ product: prd, percentage: (_total * 100) / _totalPrds });
    });
    let tempArr = [];
    tempadjustedDonationSchedule.map((item, index) => {
      let tempPrds = [];
      // Step 1: Calculate initial quantities
      let totalQuantity = 0;
      _arr.forEach(arr => {
        const tempprd = JSON.parse(JSON.stringify(arr.product));
        tempprd.quantity = (item.amount * arr.percentage) / 100;
        totalQuantity += tempprd.quantity;
        tempPrds.push(tempprd);
      });

      // Step 2: Round quantities
      let roundedTotal = 0;
      tempPrds.forEach(prd => {
        prd.quantity = Math.round(prd.quantity);
        roundedTotal += prd.quantity;
      });

      // Step 3: Adjust quantities to match item.amount
      const difference = item.amount - roundedTotal;

      // Distribute the difference
      for (let i = 0; i < Math.abs(difference); i++) {
        const indexToAdjust = i % tempPrds.length; // Loop through products
        if (difference > 0) {
          tempPrds[indexToAdjust].quantity += 1; // Increase quantity
        } else {
          tempPrds[indexToAdjust].quantity -= 1; // Decrease quantity
        }
      }

      // Step 4: Store the adjusted object
      tempArr[index] = {
        amount: item.amount,
        date: item.date,
        isMultiplierNight: item.isMultiplierNight,
        products: tempPrds,
        currency: currency,
        timeZone: timeZone,
      };
    });
    localStorage.setItem('DAILY_DONATION', JSON.stringify(tempArr));
    // console.log('all prds=', tempArr);
    // console.log('all prds=', adjustedDonationSchedule);
  };
  // handleAddPrd();

  const handleAddPrd2 = () => {
    // Create a deep copy of the adjustedDonationSchedule (which is the schedule after rounding adjustments)
    const tempAdjustedSchedule = JSON.parse(JSON.stringify(adjustedDonationSchedule));

    // Calculate the total base amount from selectedProducts.
    let totalBaseForProducts = 0;
    selectedProducts.forEach(product => {
      // Multiply product.quantity by its price (e.g. product[currency.toLowerCase()])
      const productBase = product.quantity * product[currency.toLowerCase()];
      totalBaseForProducts += productBase;
    });

    // Build an array with each product and its percentage
    const productsWithPercentage = selectedProducts.map(product => {
      const productBase = product.quantity * product[currency.toLowerCase()];
      return {
        product,
        percentage: totalBaseForProducts ? (productBase * 100) / totalBaseForProducts : 0,
      };
    });

    const donationScheduleWithProducts = tempAdjustedSchedule.map((day) => {
      // Step 1: For each product, calculate the raw allocation based on day.amount and product percentage.
      let initialAllocations = productsWithPercentage.map(item => {
        // Calculate raw allocation for the product
        const rawQuantity = (day.amount * item.percentage) / 100;
        return {
          product: JSON.parse(JSON.stringify(item.product)),
          rawQuantity, // For debugging if needed
          // We'll store the initial rounded value here:
          quantity: Math.round(rawQuantity),
        };
      });

      // Step 2: Sum the rounded quantities.
      let roundedTotal = initialAllocations.reduce((sum, p) => sum + p.quantity, 0);

      // Step 3: Compute the difference between day.amount and the sum of allocated quantities.
      const difference = day.amount - roundedTotal;

      // Step 4: Adjust quantities to fix the difference.
      // Loop over products (cycling if necessary) until the total allocated equals day.amount.
      let diffRemaining = difference;
      let idx = 0;
      while (diffRemaining !== 0) {
        // For a positive difference, increment; for a negative, decrement.
        if (diffRemaining > 0) {
          initialAllocations[idx].quantity += 1;
          diffRemaining--;
        } else if (diffRemaining < 0) {
          // Ensure we don't allocate a negative quantity.
          if (initialAllocations[idx].quantity > 0) {
            initialAllocations[idx].quantity -= 1;
            diffRemaining++;
          }
        }
        idx = (idx + 1) % initialAllocations.length;
      }

      // Step 5: Build the products array for this day.
      const productsAllocation = initialAllocations.map(item => {
        // Using product.creator as the key (adjust if you need a different identifier)
        // return { [item.product.creator]: { quantity: item.quantity } };
        item.product.quantity = item.quantity;
        // console.log("productsss=",item,)
        return item.product;
      });

      // Return the final day object including product allocations.
      return {
        date: day.date,
        shares: day.shares,
        amount: day.amount,
        isMultiplierNight: day.isMultiplierNight,
        products: productsAllocation,
        currency: currency,
        timeZone: timeZone,
        // Carry over any additional fields if needed:
        hasProduct: day.hasProduct,
        remainedAmount: day.remainedAmount,
      };
    });

    // Now, for example, store the final array in localStorage and log it.
    localStorage.setItem('DAILY_DONATION', JSON.stringify(donationScheduleWithProducts));
    // console.log('Final donation schedule with product allocations:', donationScheduleWithProducts);
  };

  handleAddPrd2();

  // const donationScheduleWithProducts = adjustedDonationSchedule.map(day => {
  //   // Compute the total base amount from selectedProducts.
  //   const totalBaseAmount = selectedProducts.reduce((sum, product) => {
  //     const productAmount = product[currency.toLowerCase()];
  //     return typeof productAmount === 'number' ? sum + productAmount : sum;
  //   }, 0);

  //   // Build an array of allocations for each product.
  //   const allocations = selectedProducts.map(product => {
  //     const productBase = typeof product[currency.toLowerCase()] === 'number'
  //       ? product[currency.toLowerCase()]
  //       : 0;
  //     const proportion = totalBaseAmount > 0 ? productBase / totalBaseAmount : 0;
  //     const rawAlloc = day.amount * proportion;
  //     const floorAlloc = Math.floor(rawAlloc);
  //     const fraction = rawAlloc - floorAlloc;
  //     return { product, floorAlloc, fraction };
  //   });

  //   // Sum the floor allocations.
  //   const sumFloor = allocations.reduce((sum, alloc) => sum + alloc.floorAlloc, 0);
  //   let remainder = day.amount - sumFloor;

  //   // Distribute the remainder by sorting allocations by fraction (descending) and incrementing.
  //   // (If remainder is 0, this loop won't run.)
  //   allocations
  //     .sort((a, b) => b.fraction - a.fraction)
  //     .forEach((alloc, index) => {
  //       if (remainder > 0) {
  //         alloc.floorAlloc += 1;
  //         remainder -= 1;
  //       }
  //     });

  //   // Now build the final products array.
  //   // Use product.creator (or another unique identifier) as the key.
  //   const productsAllocation = allocations.map(alloc => {
  //     return { [alloc.product.creator]: { quantity: alloc.floorAlloc } };
  //   });


  //   let tempArr = {
  //     date: day.date,
  //     shares: day.shares,
  //     amount: day.amount,
  //     isMultiplierNight: day.isMultiplierNight,
  //     products: productsAllocation,
  //     currency: currency,
  //     timeZone: timeZone,
  //     hasProduct: day.hasProduct,
  //     remainedAmount: day.remainedAmount,
  //   };
  //   return tempArr
  // });
  // console.log("tempArr==",donationScheduleWithProducts)
  // donationScheduleWithProducts()
  return {
    donationSchedule: adjustedDonationSchedule,
    difference: finalDifference,
  };
};
