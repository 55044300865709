import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
export default function AnnualReports() {
  /* ----------------------------- Start variables ---------------------------- */
  const pdfFiles = [
    // Annual Reports
    { title: 'Download Annual Achievements Report 2023', pdfLink: 'Achievements Report 2023.pdf', img: 'thumbnail-report-achievement-2023.png' },
    { title: 'Download Annual Impact Report 2022', pdfLink: 'matw-annualreport-2022-1.pdf', img: 'thumbnail-report-annual.png' },
    { title: 'Orphans - Annual Impact Report 2022', pdfLink: 'pdf-report-orphans.pdf', img: 'thumbnail-report-orphans.png' },
    {
      title: 'Water For Life - Annual Impact Report 2021',
      pdfLink: 'pdf-report-food-and-water-aid.pdf',
      img: 'thumbnail-report-food-and-water-aid.png',
    },
    {
      title: 'Download MATW Annual Achievement Report 2019',
      pdfLink: 'matw-projects-report-20193805.pdf',
      img: 'thumbnail-report-achievement-2019.png',
    },

    // Seasonal Reports
    { title: 'Qurban 2024 - Impact Report', pdfLink: 'MATW_Qurban_2024_Report.pdf', img: 'thumbnail-report-qurban-2024.png' },
    { title: 'Ramadan 2024 - Impact Report', pdfLink: 'MATW_Ramadan_2024_Report.pdf', img: 'thumbnail-report-ramadan-2024.png' },
    { title: 'Ramadan 2023 - Impact Report', pdfLink: 'MATW_Ramadan_2023_Report - FINAL.pdf', img: 'thumbnail-report-ramadan-2023.png' },
    { title: 'Qurban 2023 - Impact Report', pdfLink: 'MATW_Qurban_2023_Report.pdf', img: 'thumbnail-report-qurban-2023.png' },
    { title: 'Zakat - Impact Report 2022', pdfLink: 'pdf-report-zakat.pdf', img: 'thumbnail-report-zakat.png' },
    { title: 'Download Ramadan Report 2020', pdfLink: 'matw-ramadan-report-2020.pdf', img: 'thumbnail-report-ramadan-2020.png' },
    { title: 'Ramadan 2022 - Impact Report', pdfLink: 'pdf-report-ramadan.pdf', img: 'thumbnail-report-ramadan.png' },
    { title: 'Qurban - Impact Report 2022', pdfLink: 'pdf-report-current-projects.pdf', img: 'thumbnail-report-current-projects.png' },
    {
      title: 'Download Zakat Al Maal Report 2019-2020',
      pdfLink: 'matw-zakatalmal-report-2019-2020.pdf',
      img: 'thumbnail-report-zakat-2019-2020.png',
    },

    // Latest Project Impact Reports

    { title: 'Yemen - Impact Report 2021', pdfLink: 'pdf-report-crisis-and-emergencies.pdf', img: 'thumbnail-report-crisis-and-emergencies.png' },
    { title: 'Palestine Impact Report - 2023-24', pdfLink: 'Palestine Impact Report - 2023-24.pdf', img: 'thumbnail-report-palestine-2024.png' },
    {
      title: 'Palestine - Emergency Response Report 2021',
      pdfLink: 'pdf-report-ramadan-emergency.pdf',
      img: 'thumbnail-report-palestine-emergency.png',
    },
    { title: 'Morocco Earthquake - Impact Report', pdfLink: 'MATW_MoroccoEarthquake_2023_Report.pdf', img: 'thumbnail-report-morocco.png' },
  ];
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  return (
    <NewHelmetLayout>
      {seo && (
        <Helmet>
          <title>{`${seo['resourcesAndInformation']['annualReports']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol}//${window.location.host.replace(/^www\./i, "")}${window.location.pathname}`}/>
          <meta name="description" content={`${seo['resourcesAndInformation']['annualReports']['description']}`} />
        
          <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
          <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
          <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
          <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
          <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
          <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
          <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
          <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
        </Helmet>
      )}
      <div className="w-full">
        <div className="h-[460px] bg-cover sm:hidden w-full bg-[url('../public/images/reports/reporting-mobile-banner.jpg')]" />

        <img src="/images/reports/reporting-banner.jpg" className="w-full hidden sm:block" alt="Reporting" />
        <div className="px-4 max-[440px]:max-w-[340px] sm:container md:container flex relative  -mt-12 flex-col  mx-auto bg-white shadow-md rounded-md py-6">
          <div className="w-full px-4 flex justify-center">
            <div className="md:container mx-auto text-center">
              <h2 className="my-8 text-[#253B7E] font-gotcha text-6xl">Reporting</h2>
              <div className="wpb_wrapper md:px-24">
                <p className="text-[24px] text-[#14A2DC] font-bold">Trust & Transparency are our biggest assets</p>
                <div className="text-[18px] text-[#78716C] font-montserratMedium">
                  <p className="mb-5 mt-4">
                    Regular and transparent reporting on our activities, operations and performance, both in Australia and overseas, is an important
                    way we demonstrate our accountability to others.
                  </p>

                  <p className="mb-[92px]">
                    The Annual Report records MATW’s activities and performance for the financial year from 1 Jan to 31 December.It documents progress
                    against organisational goals, highlights from our relief, development and advocacy initiatives, as well as our impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-[#253B7E] text-[24px] p-2 xl:p-4 font-bold">Annual Reports</h2>
            <div className="w-full text-[§#00aeef] flex flex-wrap">
              {pdfFiles.slice(0, 5).map((file, index) => {
                return (
                  <div
                    key={`pdfs${index}`}
                    className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 p-2 xl:p-4 overflow-hidden hover:-translate-y-4 transition-all"
                  >
                    <Link to={`../pdf/${file.pdfLink}`} target="_blank">
                      <div className="overflow-hidden rounded-lg sm:rounded-xl border-[#14A2DC] border-[3px] drop-shadow-[-4px_5px_0px_rgba(220,220,220,1)] shadow-md  md:rounded-[15px] transition-all duration-300 hover:shadow-md bg-[#e3e3e3]">
                        <img
                          src={`../images/reports/${file.img}`}
                          alt={file.img}
                          className="w-full transition-all duration-300 scale-100 hover:scale-110-"
                        />
                      </div>
                      <p className="mb-5 text-center text-sm lg:text-lg mt-2">{file.title}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <h2 className="text-[#253B7E] text-[24px] p-2 xl:p-4 font-bold">Seasonal Reports</h2>
            <div className="w-full text-[#00aeef] flex flex-wrap">
              {pdfFiles.slice(5, 15).map((file, index) => {
                return (
                  <div
                    key={`pdfs${index}`}
                    className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 p-2 xl:p-4 overflow-hidden hover:-translate-y-4 transition-all"
                  >
                    <Link to={`../pdf/${file.pdfLink}`} target="_blank">
                      <div className="overflow-hidden rounded-lg sm:rounded-xl border-[#14A2DC] border-[3px] drop-shadow-[-4px_5px_0px_rgba(220,220,220,1)] shadow-md md:rounded-[15px] transition-all duration-300 hover:shadow-md bg-[#e3e3e3]">
                        <img
                          src={`../images/reports/${file.img}`}
                          alt={file.img}
                          className="w-full transition-all duration-300 scale-100 hover:scale-110-"
                        />
                      </div>
                      <p className="mb-5 text-center text-sm lg:text-lg mt-2">{file.title}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <h2 className="text-[#253B7E] text-[24px] p-2 xl:p-4 font-bold">Latest Project Impact Reports</h2>
            <div className="w-full text-[#00aeef] flex flex-wrap">
              {pdfFiles.slice(15, 19).map((file, index) => {
                return (
                  <div
                    key={`pdfs${index}`}
                    className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 p-2 xl:p-4 overflow-hidden hover:-translate-y-4 transition-all"
                  >
                    <Link to={`../pdf/${file.pdfLink}`} target="_blank">
                      <div className="overflow-hidden rounded-lg sm:rounded-xl border-[#14A2DC] border-[3px] drop-shadow-[-4px_5px_0px_rgba(220,220,220,1)] shadow-md md:rounded-[15px] transition-all duration-300 hover:shadow-md bg-[#e3e3e3]">
                        <img
                          src={`../images/reports/${file.img}`}
                          alt={file.img}
                          className="w-full transition-all duration-300 scale-100 hover:scale-110-"
                        />
                      </div>
                      <p className="mb-5 text-center text-sm lg:text-lg mt-2">{file.title}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
}
